@import '../../styles/variables';

.quizmaster {
  border-top: 0.5rem solid black;
  border-bottom: 1px solid $gray-300;
  padding: 0.5rem 0;
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 0.75rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid black;
  padding: 1rem 0;

  .status {
    margin-top: 1rem;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
  }
}
