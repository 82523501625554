.pair {
  img {
    margin-top: 0.5rem;
    max-height: 300px;
    width: auto;
    max-width: 100%;
  }
}

.divider {
  height: 1px;
  background: black;
  margin: 1rem 0;
}
