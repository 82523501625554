@import '../../styles/variables';

.social-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  padding: 3rem 0;
}

.share-buttons {
  margin-top: 1.5rem;

  & button:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.quote {
  border-radius: 1rem;
  border: 1px solid $gray-400;
  position: relative;
  padding: 0.5rem 1rem;
  max-width: 500px;
  text-align: center;
  line-height: 1.5;

  &-left,
  &-right {
    background: white;
    color: $gray-400;
    position: absolute;
  }

  &-left {
    left: -0.5rem;
    top: -0.5rem;
  }

  &-right {
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
  }
}

h3 {
  width: 100%;
  text-align: center;
  margin-top: 0;
}
