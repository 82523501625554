@import './styles/variables';

@font-face {
  font-family: 'Founders Grotesk X-Condensed';
  font-weight: 400;
  src: local('Founders Grotest X-Condensed'),
    url(./assets/fonts/FoundersGroteskXCond-Reg.woff), format('woff');
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $body;
}

input {
  font-family: $body;
}

h2 {
  font-family: 'Founders Grotesk X-Condensed';
  font-weight: 400;
  font-size: 3rem;
}
