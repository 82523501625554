@import '../../styles/variables';

.answerInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & input {
    border: none;
    border-radius: $border-radius;
    font-size: inherit;
  }

  & input[type='text'] {
    padding: 1rem;
    border: 1px solid $gray-300;
    background: $gray-100;
    width: 100%;

    &:focus-visible {
      outline: 1px solid $gray-400;
    }

    &:disabled {
      border: 1px solid $gray-200;
      background: white;
    }
  }
}
