// Colors
$green: #00955a;
$yellow: #fd9d28;
$red: #e50b18;
$blue: #35a6df;
$purple: #586994;

$gray-100: #f2f2f2;
$gray-200: #e3e3e3;
$gray-300: #d3d3d3;
$gray-400: #999;

// Typography
$header: 'Founders Grotesk X-Condenced', sans-serif;
$body: 'Georgia', serif;

// Measurements
$border-radius: 0.25rem;
