.question {
  margin-top: 0.5rem;
  font-size: 1.1rem;
}

.number {
  font-weight: bold;
}

.question-description {
  p {
    display: inline;
  }
}
