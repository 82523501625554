.scoreboard {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 1rem;
  }
}

.fanfare-button {
  color: black;
  background: transparent;

  svg {
    height: 3rem;
  }
}
