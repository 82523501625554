@import '../../styles/variables';

.answer {
  border-top: 1px solid $gray-300;
  margin: 0;
  padding: 0.5rem 0;
}

.scores {
  display: flex;
  border-top: 1px solid $gray-300;

  & button:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.points {
  display: flex;
  align-items: center;
  font-weight: bold;
  border-top: 1px solid $gray-300;

  svg {
    margin-right: 0.5rem;
  }

  &.correct svg {
    color: $green;
  }

  &.half svg {
    color: $yellow;
  }

  &.incorrect svg {
    color: $red;
  }
}

.answer-description {
  p {
    display: inline;
  }
}
