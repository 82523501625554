.fade-enter,
.fade-appear {
  opacity: 0.01;
}

.fade-enter.fade-enter-active,
.fade-appear,
.fade-appear-active {
  opacity: 1;
  transition: all 300ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: all 5ms ease-in;
}

.wrapper {
  padding: 1rem;
}
